import React, {useEffect} from "react";

const GoogleTranslate = () =>{

    try {

        const addGoogleTranslateScript = () =>{
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://translate.google.com/translate_a/element.js?cb=initGoogleTranslate';
            document.body.appendChild(script);
        };

        const addGoogleTranslate = () => {
            window.initGoogleTranslate = () => {
                if (window.google && window.google.translate) {
                  new window.google.translate.TranslateElement(
                    {
                      pageLanguage: 'pt',
                      includedLanguages: 'en,es,fr,pt',
                      layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                    },
                    'google_translate_element'
                  );
                }
              };
        }
        useEffect(() =>{
            addGoogleTranslateScript();
            addGoogleTranslate();

        }, []);

    } catch (error) {
        console.log("error", error);
    }
    return(
        <div  id="google_translate_element"></div>
    );
}

export default GoogleTranslate;