import axios from "axios";
import { getToken } from "./auth";


//LOCAL SERVER
// export const baseURL = "http://localhost:8000";
// export const apiUrl = "https://localhost:8000";

//UAT SERVER
export const apiUrl = "https://bvm-backend.siglonet.com";
 export const baseURL = "https://bvm-backend.siglonet.com";

 //LIVE SERVER
//  export const baseURL = "http://105.235.219.38:8080";
//  export const apiUrl = "http://105.235.219.38:8080";

const api = axios.create({
  baseURL: baseURL + "/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
